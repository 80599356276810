/**
 * Test if password valid or not
 *
 * @param {string} newPassword
 * @param {string} ConfirmNewPassword
 * @return {string|boolean} error string if not valid otherwise returns true
 */
export function ifValidPassword(newPassword, ConfirmNewPassword) {
  const passRegexCheck = /^(?=.*[a-zA-Z])(?=.*\d).{8,20}$/;
  if (newPassword.length >= 8 || ConfirmNewPassword.length >= 8) {
    if (newPassword === ConfirmNewPassword) {
      if (!passRegexCheck.test(newPassword)) {
        return 'Password should contain at least one number';
      }
    } else {
      return 'Passwords do not match!';
    }
  } else {
    return 'Password should be greater than 8 Characters!';
  }

  return true;
}
