/**
 * function to fire snackbar
 * anything except status: success is a danger and shows red snackbar
 *
 * @param {string} message
 * @param {string} status
 */
export function snackbarHandle(message: string, status = 'success') {
  const snackbar = $('#snackbar');
  snackbar.text(message);

  //@ts-ignore
  snackbar.removeClass('snackbar-danger', 'snackbar-success');
  snackbar.addClass(
    status === 'success' ? 'snackbar-success' : 'snackbar-danger'
  );
  snackbar.addClass('show');
  _.delay(() => {
    snackbar.removeClass('show');
  }, 3000);
}

// red snackbar, with a default message
export const snackbarError = (text: string | null = null) =>
  snackbarHandle(text || 'Some error occurred', 'd');

// green snackbar
export const snackbarSuccess = (text: string | null = null) =>
  snackbarHandle(text || 'Updated successfully');

const snackbar = snackbarHandle;
export default snackbar;
