// @ts-nocheck
import snackbar from '@helpers/snackbar';
import { Image } from 'AppTypes';

export function convertToSlug(Text: string): string {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

// returns the first error message from error response
export function getError(err) {
  return err.response.data.errors[Object.keys(err.response.data.errors)[0]][0];
}

export const uploadImage: (
  image: File,
  allowGif?: boolean
) => Promise<Image> = async (image, allowGif = false) => {
  // const config = {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // };
  // const imageForm = new FormData();
  // console.log(image.size);
  // imageForm.append('image', image, image.name);
  // imageForm.append('allow_gif', allowGif);
  // let imagePath = '';
  // try {
  return new Promise((resolve, reject) => {
    window.Vapor.store(image, { visibility: 'public-read' })
      .then(res => {
        let url = res.url;
        if (url.includes('?')) url = url.split('?')[0];
        let path = 'tmp/' + url.split('//')[1].split('tmp/')[1];
        resolve({ url, path });
      })
      .catch(err => {
        snackbar(err, 'danger');
        reject(err);
      });
  });

  // imagePath = await axios.post(
  //   route('create.temp.image').toString(),
  //   imageForm,
  //   config
  // );

  // return imagePath.data;
  // } catch (err) {
  //   if (err.response.status === 403) {
  //     snackbar('Image size is too large', 'danger');
  //   }

  //   if (err.response.status === 422) {
  //     snackbar(err.response.data.message, 'danger');
  //   }
  // }

  return false;
};

/**
 * converts a valid json to json object
 * otherwise returns string
 *
 * @param {string} str
 * @return {object|string}
 */
export function getJson(str) {
  let json = '';
  try {
    json = JSON.parse(str);
  } catch (e) {
    return str;
  }

  return json;
}

export const countDecimals = value => {
  if (Math.floor(value) !== value)
    return value.toString().split('.')[1].length || 0;

  return 0;
};
