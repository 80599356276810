/* eslint-disable no-extend-native */
/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */
import { countDecimals } from '@helpers/general';
import axios, { AxiosStatic } from 'axios';
import _ from 'lodash';
import { Config, RouteParam, RouteParams, Router } from 'ziggy-js';
window.Vapor = require('laravel-vapor');

type Route = (
  name: any,
  params?: RouteParams | RouteParam,
  absolute?: boolean,
  customZiggy?: Config
) => Router;

require('@helpers/snackbar');
require('@helpers/validators');

declare global {
  // to access the global type String
  interface String {
    toFloat(precision?: number): number;
  }

  interface Number {
    toFloat(precision?: number): number;

    validPrice(): boolean;
  }

  interface Window {
    axios: AxiosStatic;
    Vapor: any;
    _: typeof _;
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  const _: typeof _;
  const axios: AxiosStatic;
  const route: Route;
  const currency: string;
  const productImagePath: string;
  const supplierImagePath: string;
}

window._ = _;
window.axios = axios;

Number.prototype.toFloat = function (precision = 2) {
  return +this.toFixed(precision);
};

String.prototype.toFloat = function (precision = 2) {
  return +parseFloat(this.toString()).toFixed(precision);
};

// to validate any price on frontend
// to be used everywhere
Number.prototype.validPrice = function () {
  return (
    !isNaN(parseFloat(this.toString())) &&
    countDecimals(this) <= 2 &&
    this >= 0 &&
    this <= 999999.99
  );
};

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.headers.common['X-CSRF-TOKEN'] = $(
  'meta[name="csrf-token"]'
).attr('content');
